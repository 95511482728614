@import url('https://fonts.googleapis.com/css2?family=Nabla&display=swap');

/* src/styles/global.css */
body {
    margin: 0;
    font-family: 'Times New Roman', Times, serif;
    color: #fff;
    cursor: url('cursor.png'), crosshair !important;
}

marquee {
    font-size: 36px;
    color: rgb(255, 255, 54);
    font-weight: 900;
}

h1 {
    font-family: "Nabla", system-ui;
    font-size: 40px;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-variation-settings:
        "EDPT" 100,
        "EHLT" 12;
}

h2 {
    font-size: 36px;
}

p {
    font-size: 24px;
}

hr {
    border: 13px inset #ffb004;
}

.addy,
.home-section a {
    color: turquoise;
    font-family: 'Courier';
    overflow-wrap: break-word;
}

a:hover {
    color: chartreuse;
    cursor: url('cursor.png'), crosshair !important;
}

.gallery {
    display: flex;
    flex-wrap: wrap;
}

.gallery img {
    width: 550px;
    height: auto;
    max-width: 100%;
}

.home-section {
    background: rgba(0, 0, 0, 0.7);
    padding: 0 80px;
}

.about-section p {
    color: turquoise;
}

/* src/components/Header.css */
.header {
    display: flex;
    justify-content: space-between;
    padding: 20px;
}

.logo img {
    height: 40px;
}

nav ul {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
}

nav li {
    display: flex;
    align-items: center;
}

nav li img {
    height: 50px;
}

nav ul li a {
    color: blue;
    background: white;
    font-weight: bold;
    font-size: 32px;
}

@media only screen and (max-width: 600px) {
    .home-section {
        background: rgba(0, 0, 0, 0.7);
        padding: 0 10px;
    }

    nav ul li a {
        font-size: 24px;
    }

    nav li img {
        height: 25px;
    }
}