/* src/components/Background.css */
.video-background {
    position: fixed;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    z-index: -1;
}

.video {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -1;
    transform: translate(-50%, -50%);
    background-size: cover;
}

.content-overlay {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: white;
    text-align: center;
}